import {Component, Input, OnInit} from '@angular/core';
import {Supplier} from "../../../types/backend-types";

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit {
  @Input()
  supplier!: Supplier;

  constructor() {
  }

  ngOnInit(): void {
  }

}
