<app-drawer location="suppliers"></app-drawer>
<div id="content">
  <div class="two-set">
    <h1>Dostawcy</h1>
    <button mat-raised-button color="primary" (click)="openDialog()">Dodaj dostawcę</button>
  </div>
  <div id="card-arranger">
    <mat-card>
      <div class="name">Nazwa</div>
      <div class="address">Adres</div>
      <div class="contact">Osoba kontaktowa</div>
    </mat-card>
    <ng-container *ngIf="suppliers">
      <ng-container *ngFor="let supplier of suppliers">
        <app-supplier-strip [supplier]="supplier" [selected]="detailedSupplier?.id===supplier.id"
                            (click)="pickSupplier(supplier)"></app-supplier-strip>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-supplier-details *ngIf="suppliers&&suppliers.length>0"
                      [supplier]="detailedSupplier?detailedSupplier:suppliers[0]"></app-supplier-details>
