import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {Supplier} from "../types/backend-types";

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private http: HttpClient) {
  }

  private handleError<T>(operation: string = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(`${operation} failed!`)
      console.error(error);
      return of(result as T);
    };
  }

  backendUrl = `${environment.backendURL}:${environment.backendPort}/rest/supplier`
  searchUrl = `${environment.backendURL}:${environment.backendPort}/rest/multiPicker/search`

  buildOptions() {
    const sessionToken = sessionStorage.getItem('milk-spin-token')
    const token = sessionToken ? sessionToken : environment.token;
    return {headers: {"X-MilkSpin-AccessToken": `${token}`}}
  }

  createSupplier(newSupplier: Supplier) {
    return this.http.post<any>(`${this.backendUrl}/`, newSupplier, this.buildOptions()).pipe(
      catchError(this.handleError<any>('createSupplier', []))
    )
  }

  getSupplier(supplierId: string) {
    return this.http.get(`${this.backendUrl}/${supplierId}`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('getSupplier', []))
    )
  }

  getAllSuppliers() {
    return this.http.get(`${this.backendUrl}/all`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('getSupplier', []))
    )
  }

  deleteSupplier(supplierId: string) {
    return this.http.delete(`${this.backendUrl}/${supplierId}`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('deleteSupplier', []))
    )
  }

  updateSupplier(supplierId: string, supplier: Supplier) {
    return this.http.put(`${this.backendUrl}/${supplierId}`, supplier, this.buildOptions()).pipe(
      catchError(this.handleError<any>('updateSample', []))
    )
  }

  getFilteredSuppliers(query: string) {
    return this.http.get(`${this.searchUrl}?types=milkSupplier&query=${query}&offset=0&limit=20`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('searchSuppliers', []))
    )
  }
}
