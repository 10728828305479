export type Sample = {
  id?: string,
  name?: string,
  pixelSize: any,
  images: Array<Image>,
  resultReady?: boolean,
  result?: AnalysisResult,
  analysisStatus?: "WORKING" | "IN_PROGRESS" | "ARCHIVED",
  notes?: string,
  started?: string,
  created?: string,
  supplierId?: string,
  supplierName?: string,
  probability?: string
}
export const defaultSample = {
  pixelSize: 0.3,
  images: [],
  supplierId: "632cc3bc0fd63524773ec579"
}
type AnalysisResult = {
  status: any,
  message: any,
  centrifuged?: boolean,
  recommendations: any,
  probability?: number
}
export type Image = {
  url: string,
  thumbnail: string,
  name?: string,
  id?: string
}
export const imagePlaceholder = {
  "name": "iddi9ididd",
  "url": "urlr",
  "thumbnail": "kdokdo"
}
export type Supplier = {
  id?: string,
  name: string,
  data: SupplierData
}
type SupplierData = {
  street: string,
  city: string,
  postCode: string,
  contactName: string,
  email: string,
  phone?: string,
}
