export const AIEngineErrors = {
  "pl.promity.ai.milkspin.error.invalid-request": 'Malformed request, no JSON.',
  "pl.promity.ai.milkspin.error.missing-sample-id": 'Payload without sample_id.',
  "pl.promity.ai.milkspin.error.unknown-sample-id": 'Given sample_id is not found in the DB.',
  "pl.promity.ai.milkspin.error.invalid-image-set": 'Image URLs are not of the expected list[str] type.',
  "pl.promity.ai.milkspin.error.undefined-pixel-size": 'Pixel size is still undefined.',
  "pl.promity.ai.milkspin.error.too-few-images": 'There are no images in the sample.',
  "pl.promity.ai.milkspin.error.too-many-images": 'There are too many images within sample.',
  "pl.promity.ai.milkspin.error.unknown-image-url": 'Given URL is not part of the sample.',
  "pl.promity.ai.milkspin.error.unreachable-image-url": 'Given URL is not reachable.',
  "pl.promity.ai.milkspin.busy.downloading-images": '	Backend is downloading image(s).',
  "pl.promity.ai.milkspin.busy.preparing-images": 'Backend is preprocessing image(s).',
  "pl.promity.ai.milkspin.busy.analyzing-sample": 'Backend is analysing the sample.',
  "pl.promity.ai.milkspin.ready.analysed": 'Analysis has finished.',
  "pl.promity.ai.milkspin.ready.sample-deleted": 'Sample successfully deleted.',
  "pl.promity.ai.milkspin.ready.sample-images-deleted": 'Image(s) successfully deleted.',
  "pl.promity.ai.milkspin.waiting.for-images": 'Awaiting more images before starting analysis.',
  "pl.promity.ai.milkspin.error.image-too-small": 'Uploaded image(s) to small'
}
