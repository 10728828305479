<mat-card [ngClass]="selected?'selected':''">
  <div class="name"><img id="supplier_avatar" src="assets/icon_house.svg"/><span>{{supplier.name}}</span></div>
  <div class="address"><span>{{supplier.data.street}}; {{supplier.data.postCode}} {{supplier.data.city}}</span></div>
  <div class="contact"><span>{{supplier.data.contactName}}</span></div>
  <div id="actions_wrapper">
    <button mat-icon-button [matMenuTriggerFor]="more_menu" (click)="$event.stopPropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #more_menu="matMenu">
      <button mat-menu-item (click)="editSupplier()">
        EDYTUJ
      </button>
      <button mat-menu-item (click)="deleteSupplier()">
        USUŃ
      </button>
    </mat-menu>
  </div>
</mat-card>
