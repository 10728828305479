<app-drawer location="analysis"></app-drawer>
<div id="analysis-wrapper">
  <div id="top">
    <h1>Analiza mleka</h1>
  </div>
  <div id="flexer">
    <app-samples (selectionEvent)="selectionHandler($event)"></app-samples>
    <app-sample-detail [sample]="detailedSample" [image]="detailedImage"></app-sample-detail>
  </div>
</div>
