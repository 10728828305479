import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Sample, Image, Supplier} from "../../../types/backend-types";
import {EditSampleComponent} from "../../common/edit-sample/edit-sample.component";
import {MatDialog} from "@angular/material/dialog";
import {SamplesService} from "../../../services/samples.service";
import {SuppliersService} from "../../../services/suppliers.service";
import {AIEngineErrors} from "../../../types/AIEngineErrors";

@Component({
  selector: 'app-sample-detail',
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.scss']
})
export class SampleDetailComponent implements OnInit, OnChanges {
  @Input()
  sample: Sample | undefined;
  @Input()
  image: Image | undefined;
  cheeseRecommended: boolean = false;
  butterRecommended: boolean = false;
  milkRecommended: boolean = false;
  probabilityPercentValue: number = 0;
  supplier: Supplier | undefined;
  errorMessage: any = "";

  constructor(public dialog: MatDialog, private backend: SamplesService, private supplierService: SuppliersService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['sample'];
    if (change && change.currentValue !== change.previousValue) {
      console.log(change)
      // @ts-ignore
      this.errorMessage = this.sample?.result?.status === "ERROR" ? AIEngineErrors[this.sample?.result?.message] : '';
      if (this.sample?.result?.recommendations) {
        this.milkRecommended = !!this.sample.result.recommendations.find((item: any) => item == "uht");
        this.butterRecommended = !!this.sample.result.recommendations.find((item: any) => item == "cream");
        this.cheeseRecommended = !!this.sample.result.recommendations.find((item: any) => item == "yoghurt");
        this.probabilityPercentValue = Math.floor(this.sample.result.probability && this.sample.result.probability < 1 ? this.sample.result.probability * 100 : 0)
      }
    }
  }

  openDialog() {
    if (this.sample?.id) {
      const dialogRef = this.dialog.open(EditSampleComponent, {
        width: '600px',
        data: {sample: this.sample}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('dialogClosed:', result)
        window.location.reload();
      })
    }
  }

  updateNotes() {
    if (this.sample?.id) {
      this.backend.updateSample(this.sample.id, this.sample).subscribe(response =>
        console.log(response))
    }
  }

}
