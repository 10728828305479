import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {EditSampleComponent} from "../common/edit-sample/edit-sample.component";
import {MatDialog} from "@angular/material/dialog";
import {DemoPlayerComponent} from "../demo-player/demo-player.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sessionTokenKey='milk-spin-token';
  devMode:boolean=false;
  consent:boolean=false;

  constructor(private route:ActivatedRoute, private router:Router, private dialog:MatDialog) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token=params['token']
      if(token){
        if(sessionStorage.getItem(this.sessionTokenKey)!==token){
          sessionStorage.removeItem(this.sessionTokenKey);
          sessionStorage.setItem(this.sessionTokenKey,token);
        }
      }else{
        this.devMode=true;
        if(sessionStorage.getItem(this.sessionTokenKey))
        {
          sessionStorage.removeItem(this.sessionTokenKey);
        }
      }
    });
  }
  goToAnalysis(){
    this.router.navigateByUrl('/analysis').then(result=>{
    if(result)console.log(result)
    })
  }
  consentChanged(event:any){
    //:TODO set choice in cookies

  }
  openDialog(){
    const dialogRef =this.dialog.open(DemoPlayerComponent,{
      width:'1100px',
      height:'920px',
    });
    dialogRef.afterClosed().subscribe(result=>{
      console.log('dialogClosed:', result)
    })
  }

}
