<mat-expansion-panel>
  <mat-expansion-panel-header>
    <div id="duoLiner">
      <div class="first-line">
        <div class="name">{{sample.name}}</div>
        <div class="number"> ({{sample.images.length}})</div>
      </div>
      <span id="result-indicator">
        <mat-icon class="number" *ngIf="sample?.notes" svgIcon="app_comment"></mat-icon>
        <span *ngIf="sample?.resultReady">
        <span *ngIf="sample?.result?.centrifuged && !errorMessage" class="bad">Wirowane</span>
          <span *ngIf="!sample?.result?.centrifuged && !errorMessage" class="good">Niewirowane</span>
          <span *ngIf="errorMessage" class="bad">Błąd analizy próbki</span>
        </span>
        <span *ngIf="sample?.started===null" id="not-running">Analiza nie uruchomiona</span>
        <span *ngIf="sample?.started&&sample?.resultReady===false"><mat-spinner diameter="15"></mat-spinner></span>
      </span>
    </div>
    <div id="more-button" *ngIf="sample.analysisStatus!=='ARCHIVED'">
      <button mat-icon-button [matMenuTriggerFor]="more_menu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #more_menu="matMenu">
        <button mat-menu-item *ngIf="sample.analysisStatus==='IN_PROGRESS'" (click)="removeSample(sample)">
          USUŃ
        </button>
        <button mat-menu-item *ngIf="sample.analysisStatus==='IN_PROGRESS'" (click)="editSample(sample)">
          EDYTUJ
        </button>
        <button mat-menu-item (click)="moveToArchive(sample)" *ngIf="sample.analysisStatus==='WORKING'">
          ARCHIWIZUJ
        </button>
      </mat-menu>
    </div>
  </mat-expansion-panel-header>
  <div id="images-aligner">
    <ng-container *ngFor="let image of sample.images; index as i">
      <button mat-flat-button (click)="imageSelected(i)" class="image-button">{{image.name}}</button>
    </ng-container>
  </div>

</mat-expansion-panel>
