<div id="top">
  <img src="assets/icon_house.svg">
  <h3>{{supplier.name}}</h3>
</div>
<div id="bottom">
  <h3>Informacje kontaktowe</h3>
  <div class="icon_data">
    <mat-icon svgIcon="app_mail"></mat-icon>
    <div class="data">
      <span>{{supplier.data.contactName}}</span>
      <span>{{supplier.data.email}}</span>
    </div>
  </div>
  <div class="icon_data">
    <mat-icon svgIcon="app_phone"></mat-icon>
    <div class="data">
      <span>{{supplier.data.phone}}</span>
    </div>

  </div>
  <div class="icon_data">
    <mat-icon svgIcon="app_location"></mat-icon>
    <div class="data">
      <span>{{supplier.data.street}}</span>
      <span>{{supplier.data.postCode}} {{supplier.data.city}}</span>
    </div>
  </div>

</div>
