import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, tap, pipe, of, Observable} from "rxjs";
import {Sample} from "../types/backend-types";

@Injectable({
  providedIn: 'root'
})


export class SamplesService {
  constructor(private http: HttpClient) {
  }

  private handleError<T>(operation: string = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(`${operation} failed!`)
      console.error(error);
      return of(result as T);
    };
  }

  backendUrl = `${environment.backendURL}:${environment.backendPort}/rest/analysis`

  buildOptions() {
    const sessionToken = sessionStorage.getItem('milk-spin-token')
    const token = sessionToken ? sessionToken : environment.token;
    return {headers: {"X-MilkSpin-AccessToken": `${token}`}}
  }

  getSample(sampleId: string) {
    return this.http.get(`${this.backendUrl}/${sampleId}`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('getSample', []))
    )
  }

  createSample(newSample: Sample) {
    return this.http.post<any>(`${this.backendUrl}/`, newSample, this.buildOptions()).pipe(
      catchError(this.handleError<any>('createSample', []))
    )
  }

  deleteSample(sampleId: string) {
    return this.http.delete(`${this.backendUrl}/${sampleId}`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('deleteSample', []))
    )
  }

  updateSample(sampleId: string | undefined, sample: any) {
    return this.http.put(`${this.backendUrl}/${sampleId}`, sample, this.buildOptions()).pipe(
      catchError(this.handleError<any>('updateSample', []))
    )
  }

  startAnalysis(sampleId: string | undefined, sample: any) {
    return this.http.post(`${this.backendUrl}/start/${sampleId}`, sample, this.buildOptions()).pipe(
      catchError(this.handleError<any>('startAnalysis', []))
    )
  }

  getAllSamples() {
    return this.http.get<Array<Sample>>(`${this.backendUrl}/all`, this.buildOptions()).pipe(
      catchError(this.handleError<any>('getAllSample', []))
    )
  }
}
