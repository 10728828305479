import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {SamplesService} from "../../../services/samples.service";
import {Sample, defaultSample, Image, Supplier} from "../../../types/backend-types";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UploadImageService} from "../../../services/upload-image.service";
import {SuppliersService} from "../../../services/suppliers.service";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";

@Component({
  selector: 'app-edit-sample',
  templateUrl: './edit-sample.component.html',
  styleUrls: ['./edit-sample.component.scss']
})
export class EditSampleComponent implements OnInit, AfterViewInit {
  tempFiles: Array<any> = [];
  uploadAllowed: boolean = true;
  analysing: boolean = false;
  sample: Sample = {...defaultSample};
  isEditMode: boolean = false;
  sampleBackup: Sample | undefined;
  sampleCreationErrored: boolean = false;
  fileToUpload: File | undefined;
  supplierControl=new FormControl(' ')
  suppliers: Array<Supplier> = [];
  filteredSuppliers:Observable<Supplier[]>= new Observable<Supplier[]>();
  pixelSizeTooltip = "Rozdzielczość mikroskopu którym wykonano zdjęcie w µm/px";


  constructor(public dialogRef: MatDialogRef<EditSampleComponent>,
              private backend: SamplesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private uploadImage: UploadImageService,
              private suppliersService: SuppliersService) {
  }

  ngOnInit(): void {
    this.filteredSuppliers=this.supplierControl.valueChanges.pipe(
      startWith(''),
      map(value=>this._filter(value||' '))
    );
    this.sample = {...this.data.sample};
    console.log("edit-sample", this.sample)
    this.sampleBackup = JSON.parse(JSON.stringify(this.data.sample));
    if (this.data.fileToUpload) {
      this.fileToUpload = this.data.fileToUpload
    }
    this.suppliersService.getAllSuppliers().subscribe((response: Array<Supplier>) => {
      this.suppliers = response;
      console.log("suppliers:", response)
      if(this.suppliers?.length>1){
        this.sample.supplierName=this.suppliers[0].name;
        this.sample.supplierId=this.suppliers[0].id;
      }
    })
    this.supplierControl.setValue(this.sample.supplierName)
  }

  ngAfterViewInit(): void {
    if (!this.sample.id) {
      this.backend.createSample(this.sample).subscribe((response: Sample) => {
        if (response.id) {
          this.sample = response;
          if (this.fileToUpload) {
            this.uploadStartedHandler(this.fileToUpload.name);
            this.uploadImage.uploadImage(this.fileToUpload, response.id).then(result => {
              this.uploadDoneHandler(result);
            }).catch(error => console.error(error));
          }
        } else {
          this.sampleCreationErrored = true;
        }
      })
    } else {
      this.isEditMode = true;
    }
  }
  private _filter(value: string|Supplier): Supplier[] {
    const filterValue = typeof value ==='string'? value.toLowerCase():value.name.toLowerCase();

    return this.suppliers.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  uploadStartedHandler(event: any) {
    this.uploadAllowed = false;
    this.tempFiles.push(event)
  }

  uploadDoneHandler(event: Image) {
    this.uploadAllowed = true;
    this.tempFiles.pop();
    this.sample.images.push(event)//check image
    this.updateSample();
    console.log(event)
  }

  updateSample() {
    if (this.sample.pixelSize && this.sample.name) {
      this.backend.updateSample(this.sample.id, this.sample).subscribe((response: Sample) => {
        console.log(response)
        if (response.id) {
          this.sample = response;
        }
      })

    }

  }

  validateImagesCount() {
    if (this.sample) {
      return this.sample.images.length <= 16 && this.sample.images.length > 4
    } else {
      return false
    }
  }

  startAnalysis() {
    this.analysing = true;
    this.backend.startAnalysis(this.sample.id, this.sample).subscribe(response => {
      this.dialogRef.close()
      console.log(response)
    })
  }

  deleteImageHandler(event: any, imageUrl: Image) {
    if (event === true) {
      this.sample.images.splice(this.sample.images.findIndex((element: any) => element == imageUrl), 1)
      this.updateSample()
    }
  }

  deleteSample() {
    if (this.sample.id) {
      this.backend.deleteSample(this.sample.id).subscribe(response => {
        this.dialogRef.close("User Canceled")
      })
    }
  }

  restoreAndClose() {
    this.backend.updateSample(this.sample.id, this.sampleBackup).subscribe(response => {
      console.log(response);
      this.dialogRef.close("Edit Canceled")
    })
  }

  supplierDisplayFn(supplier: any) {
    return supplier && supplier.name ? supplier.name : supplier;
  }


  supplierPicked(changeEvent: any) {
    this.sample.supplierId = changeEvent.option.value.id;
    this.sample.supplierName = changeEvent.option.value.name;
    this.updateSample();
  }
  saveAndExit(){
    this.updateSample();
    this.dialogRef.close("Work in progress saved")
  }
}
