<h1 mat-dialog-title *ngIf="!supplier?.id">Dodaj dostawcę</h1><h1 mat-dialog-title *ngIf="supplier?.id">Edytuj
  dostawcę</h1>
<div id="avatar_wrapper">
  <img id="supplier_avatar" src="assets/icon_house.svg"/>
</div>
<div mat-dialog-content id="supplier-editor">
  <mat-form-field appearance="outline">
    <mat-label>
      Nazwa dostawcy
    </mat-label>
    <input matInput [(ngModel)]="supplier.name" required #supplierName>
    <mat-error *ngIf="supplierName.validity.valueMissing"> Pole waymagane</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>
      Ulica i numer
    </mat-label>
    <input matInput [(ngModel)]="supplier.data.street" required
           pattern="^[a-z A-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]*[0-9\/\\m.]{1,10}$" #street>
    <mat-error *ngIf="street.validity.valueMissing"> Pole waymagane</mat-error>
    <mat-error *ngIf="street.validity.patternMismatch">Proszę nie zapomnieć o numerze</mat-error>
  </mat-form-field>
  <div id="cityCode">
    <mat-form-field appearance="outline">
      <mat-label>
        Miasto
      </mat-label>
      <input matInput [(ngModel)]="supplier.data.city" required #city>
      <mat-error *ngIf="city.validity.valueMissing"> Pole waymagane</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        Kod pocztowy
      </mat-label>
      <input matInput [(ngModel)]="supplier.data.postCode" required pattern="^[0-9]{2}-[0-9]{3}$" #postCode>
      <mat-error *ngIf="postCode.validity.valueMissing"> Pole waymagane</mat-error>
      <mat-error *ngIf="postCode.validity.patternMismatch">Proszę podać kod pocztowy w fomracie 00-000</mat-error>
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>
      Osoba kontaktowa
    </mat-label>
    <input matInput [(ngModel)]="supplier.data.contactName" required #contactName>
    <mat-error *ngIf="contactName.validity.valueMissing"> Pole waymagane</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>
      E-mail
    </mat-label>
    <input matInput [(ngModel)]="supplier.data.email" required pattern="^\S+@\S+\.\S+$" #email>
    <mat-error *ngIf="email.validity.valueMissing"> Pole waymagane</mat-error>
    <mat-error *ngIf="email.validity.patternMismatch">Niepoprawny email</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>
      Telefon kontaktowy
    </mat-label>
    <input matInput [(ngModel)]="supplier.data.phone" #phone pattern="^[+]? *[ 0-9()]{9,26}$">
    <mat-error *ngIf="phone.validity.patternMismatch">Niepoprawny numer telefonu</mat-error>

  </mat-form-field>
  <button mat-raised-button color="primary" (click)="supplier.id?updateSupplier():createSupplier()"
          [disabled]="!(supplierName.validity.valid&&street.validity.valid&&city.validity.valid&&postCode.validity.valid&&contactName.validity.valid&&phone.validity.valid&&email.validity.valid)">
    {{supplier.id ? "Aktualizuj dostawcę" : "Dodaj dostawcę"}}
    <mat-spinner diameter="24" id="spinner" *ngIf="updating"></mat-spinner>
  </button>
  <button mat-stroked-button (click)="supplier.id?restoreAndClose():close()">Anuluj</button>
</div>
