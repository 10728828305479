<div id="samples-wrapper">
  <div id="tabber">
    <button [ngClass]="tabSelected==1?'mat-stroked-button':'mat-flat-button'" (click)="tabChange(1)">Robocze</button>
    <button [ngClass]="tabSelected==2?'mat-stroked-button':'mat-flat-button'" (click)=tabChange(2)>Wykonane</button>
    <button [ngClass]="tabSelected==3?'mat-stroked-button':'mat-flat-button'" (click)=tabChange(3)>Archiwalne</button>
  </div>
  <div id="content-empty" *ngIf="displayedSamples.length===0">
    <img src="assets/empty_01.svg" id="empty-samples-image">
    <h3>Tutaj będzie Twoja historia próbek</h3>
    <p>wygeneruj pierwsze próbki mleka, aby zobaczyć swoją historię</p>
  </div>
  <div id="content">
    <ng-container *ngFor="let sample of displayedSamples; index as i" class="outer">
      <app-sample-summary [sample]="sample" (imageSelectedEvent)="imageSelect($event)" (click)="sampleSelect(i)"
                          (sampleChanged)="updateSample($event)"
                          (sampleRemoved)="sampleRemoved($event)"></app-sample-summary>
    </ng-container>
  </div>
</div>
