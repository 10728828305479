import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from "@angular/material/button";
import {HttpClientModule} from "@angular/common/http";
import {DrawerComponent} from './components/drawer/drawer.component';
import {DetailsComponent} from './components/details/details.component';
import {MatIconModule} from "@angular/material/icon";
import {MilkAnalysisComponent} from './components/milk-analysis/milk-analysis.component';
import {SamplesComponent} from './components/milk-analysis/samples/samples.component';
import {SampleDetailComponent} from './components/milk-analysis/sample-detail/sample-detail.component';
import {UploaderComponent} from './components/common/uploader/uploader.component';
import {EditSampleComponent} from './components/common/edit-sample/edit-sample.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ImageStatusComponent} from './components/common/image-status/image-status.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SampleSummaryComponent} from './components/milk-analysis/sample-summary/sample-summary.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatMenuModule} from "@angular/material/menu";
import {ConfirmDialogComponent} from './components/common/confirm-dialog/confirm-dialog.component';
import {MatSliderModule} from "@angular/material/slider";
import {A11yModule} from "@angular/cdk/a11y";
import {FidelitySliderComponent} from './components/common/fidelity-slider/fidelity-slider.component';
import {HomeComponent} from './components/home/home.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DemoPlayerComponent} from './components/demo-player/demo-player.component';
import {SuppliersComponent} from './components/suppliers/suppliers.component';
import {MatCardModule} from "@angular/material/card";
import {EditSupplierComponent} from './components/suppliers/edit-supplier/edit-supplier.component';
import {SupplierStripComponent} from './components/suppliers/supplier-strip/supplier-strip.component';
import {SupplierDetailsComponent} from './components/suppliers/supplier-details/supplier-details.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    AppComponent,
    DrawerComponent,
    DetailsComponent,
    MilkAnalysisComponent,
    SamplesComponent,
    SampleDetailComponent,
    UploaderComponent,
    EditSampleComponent,
    ImageStatusComponent,
    SampleSummaryComponent,
    ConfirmDialogComponent,
    FidelitySliderComponent,
    HomeComponent,
    DemoPlayerComponent,
    SuppliersComponent,
    EditSupplierComponent,
    SupplierStripComponent,
    SupplierDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatExpansionModule,
    FormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSliderModule,
    A11yModule,
    MatCheckboxModule,
    MatCardModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
