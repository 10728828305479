<div id="drawer_wrapper">
  <img id="app-logo" src="./assets/logo.svg" (click)="navigateHome()">
  <button mat-flat-button class="buttons drawer-buttons" (click)="goToAnalysis()" [disabled]="location==='analysis'">
      <span class="set">
      <mat-icon aria-label="samples-icon" svgIcon="app_analysis"></mat-icon>
      <span>Analiza mleka</span>
    </span>
  </button>
  <button mat-flat-button class="buttons drawer-buttons" (click)="goToSuppliers()" [disabled]="location==='suppliers'">
    <span class="set">
      <mat-icon aria-label="samples-icon" svgIcon="app_suppliers"></mat-icon>
      <span>Dostawcy</span>
    </span>
  </button>
  <div id="new-sample-wrapper">
    <button mat-raised-button class="buttons" color="primary" (click)="openDialog()">Wgraj próbkę</button>
  </div>
</div>
