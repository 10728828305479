import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {EditSampleComponent} from "../common/edit-sample/edit-sample.component";
import {defaultSample} from "../../types/backend-types";
import {Router} from "@angular/router";

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @Input()
  location!: string

  date = new Date().toLocaleDateString("pl-PL")

  constructor(public dialog: MatDialog, private router: Router) {
  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditSampleComponent, {
      width: '600px',
      data: {sample: {...defaultSample, name: `${this.date.toString()} Nowa próbka`}}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialogClosed:', result)
      window.location.reload();
    })
  }

  goToAnalysis() {
    this.router.navigateByUrl('/analysis').then().catch(error => console.error(error))
  }

  goToSuppliers() {
    this.router.navigateByUrl('/suppliers').then().catch(error => console.error(error))
  }

  navigateHome() {
    this.router.navigateByUrl('/').then().catch(error => console.error(error))
  }

}
