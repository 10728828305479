import {Component, Input, OnInit} from '@angular/core';
import {Supplier} from "../../../types/backend-types";
import {SuppliersService} from "../../../services/suppliers.service";
import {MatDialog} from "@angular/material/dialog";
import {EditSupplierComponent} from "../edit-supplier/edit-supplier.component";
import {ConfirmDialogComponent} from "../../common/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-supplier-strip',
  templateUrl: './supplier-strip.component.html',
  styleUrls: ['./supplier-strip.component.scss']
})
export class SupplierStripComponent implements OnInit {
  @Input()
  supplier!: Supplier;
  @Input()
  selected!:boolean;

  constructor(private supplierService: SuppliersService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  deleteSupplier() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {message: "Czy na pewno chcesz usunąć " + this.supplier.name + "?"}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.supplier.id) {
        this.supplierService.deleteSupplier(this.supplier.id).subscribe((response: any) => {
          console.log('Delete supplier response: ', response)
          window.location.reload();
        })
      }
    })
  }

  editSupplier() {
    const dialogRef = this.dialog.open(EditSupplierComponent, {
      width: '600px',
      data: {supplier: this.supplier}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('editSupplierDialog closed with result:', result)
      window.location.reload();
    })
  }
}
