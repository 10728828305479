import { Component, OnInit } from '@angular/core';
import {Sample, Image} from "../../types/backend-types";

@Component({
  selector: 'app-milk-analysis',
  templateUrl: './milk-analysis.component.html',
  styleUrls: ['./milk-analysis.component.scss']
})
export class MilkAnalysisComponent implements OnInit {
detailedSample?:Sample;
detailedImage?:Image;

  constructor() { }

  ngOnInit(): void {
  }
  selectionHandler(event:any){
    if(event.sample){
      this.detailedSample=event.sample;
    }
    if(event.image) {
      this.detailedImage = event.image;
    }
  }

}
