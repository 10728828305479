import {Component, OnInit} from '@angular/core';
import {SuppliersService} from "../../services/suppliers.service";
import {MatDialog} from "@angular/material/dialog";
import {EditSupplierComponent} from "./edit-supplier/edit-supplier.component";
import {Supplier} from "../../types/backend-types";

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {
  suppliers: Array<Supplier> | undefined;
  detailedSupplier: Supplier | undefined;

  constructor(private suppliersService: SuppliersService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.suppliersService.getAllSuppliers().subscribe((response: any) => {
      console.log(response);
      this.suppliers = response;
      this.pickSupplier(this.suppliers ? this.suppliers[0] : response[0])
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditSupplierComponent, {
      width: '600px',
      data: {supplier: undefined}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialogClosed:', result)
      window.location.reload();
    })
  }

  pickSupplier(supplier: Supplier) {
    this.detailedSupplier = supplier;
  }

}
