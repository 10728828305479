import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Sample} from "../../../types/backend-types";
import {SamplesService} from "../../../services/samples.service";
import {EditSampleComponent} from "../../common/edit-sample/edit-sample.component";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../common/confirm-dialog/confirm-dialog.component";
import {AIEngineErrors} from "../../../types/AIEngineErrors";

@Component({
  selector: 'app-sample-summary',
  templateUrl: './sample-summary.component.html',
  styleUrls: ['./sample-summary.component.scss']
})
export class SampleSummaryComponent implements OnInit {
  @Input()
  sample!: Sample;
  @Output()
  sampleChanged: EventEmitter<Sample> = new EventEmitter<Sample>();
  @Output()
  sampleRemoved: EventEmitter<Sample> = new EventEmitter<Sample>();
  @Output()
  imageSelectedEvent: EventEmitter<number> = new EventEmitter<number>();
  errorMessage: string = '';

  constructor(private backend: SamplesService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    // @ts-ignore
    this.errorMessage = this.sample?.result?.status === "ERROR" ? AIEngineErrors[this.sample?.result?.message] : '';
  }


  imageSelected(index: number) {
    this.imageSelectedEvent.emit(index)
  }

  moveToArchive(sample: Sample) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {message: "Przenoszenie do archiwum jest nieodwracalne. Czy chcesz kontynuować?"}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backend.updateSample(sample.id, {...sample, analysisStatus: "ARCHIVED"}).subscribe(response =>
          this.sampleChanged.emit(response)
        )
      }
    })
  }

  removeSample(sample: Sample) {
    if (sample.id) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '500px',
        data: {message: "Czy na pewno chcesz usunąć " + sample.name + "?"}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && sample.id) {
          this.backend.deleteSample(sample.id).subscribe(response =>
            this.sampleRemoved.emit(sample)
          )
        }
      })
    }
  }

  editSample(sample: Sample) {
    const dialogRef = this.dialog.open(EditSampleComponent, {
      width: '600px',
      data: {sample: sample}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialogClosed:', result)
      window.location.reload();
    })
  }


}
