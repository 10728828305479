<div id="main">
  <div id="center">
    <div id="content">
      <div id="content-left">
        <img src="assets/logo.svg">
        <h1>Witaj w MilkSpin.ai!</h1>
        <p>Dzięki naszej aplikacji możesz przeprowadzić analizę próbki mleka pod kątem wirowania i uzyskać rekomendacje
          co do wyboru procesu technologicznego.</p>
        <h2>Zobacz demo</h2>
        <p>Zapytaj nas o demo, aby zobaczyć jak działa nasza aplikacja.</p>
        <button mat-stroked-button color="primary" id="demo-button" (click)="openDialog()">Zobacz demo</button>
        <h2>Wypróbuj wersję próbną</h2>
        <p>Sprawdź naszą aplikację korzystając z 14-to dniowego okresu próbnego</p>
        <mat-checkbox id="checkbox" #checkbox [(ngModel)]="consent" (ngModelChange)="consentChanged($event)">
            <span *ngIf="!devMode">
            Potwierdzam, że aplikacja milkspin.ai. zostanie wykorzystana wyłącznie pod analizę próbek mleka, a
            dzielenie się wynikami analizy poprzez platformy social media, bez zgody właściwe aplikacji, nie jest
            dozwolone. <a href="mailto:milkspinai@promity.com">Kontakt</a>
          </span>
          <span *ngIf="devMode">
              Uruchamiam aplikację we współdzielonym profilu developersko-testerskim i wiem co się z tym wiąże
            </span>
        </mat-checkbox>
        <button mat-raised-button color="primary" (click)="goToAnalysis()" [disabled]="!checkbox.checked">Wypróbuj za
          darmo
        </button>
      </div>
      <div id="content-right">
        <img src="assets/cows.png">

      </div>
    </div>

  </div>
  <div id="bottom">
    <div id="left">
      <div class="two-set">
        <h3>Aplikacja została dofinansowana dzięki środkom z funduszy europejskich</h3>
        <img src="assets/founds-set.png"></div>
    </div>
    <div id="right">
      <div class="two-set">
        <h3>Aplikacja powstała przy współpracy</h3>
        <img src="assets/creators-set.png">
      </div>
    </div>
  </div>
</div>
