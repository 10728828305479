import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {UploadImageService} from "../../../services/upload-image.service";
import {Sample, defaultSample} from "../../../types/backend-types";
import {EditSampleComponent} from "../edit-sample/edit-sample.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input()
  sampleId!: string;
  @Input()
  uploadAllowed!: boolean;
  @Input()
  disabled: boolean = false;
  @Output()
  uploadStartedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  fileUploadedEvent: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.uploadFile(files[0])
    }
  }

  fileToUpload: File | undefined;
  date = new Date().toLocaleDateString("pl-PL");
  defaultSample: Sample = {
    ...defaultSample, name: `${this.date} Nowa próbka`
  }

  constructor(
    private uploadImage: UploadImageService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    console.log('DISABLED:', this.disabled)
  }

  onFileSelected(event: any) {
    const file = event.target.files.item(0);
    this.uploadFile(file)

  }

  uploadFile(file: File) {
    if (!this.disabled) {
      this.uploadStartedEvent.emit(file.name)
      if (this.sampleId) {
        this.uploadImage.uploadImage(file, this.sampleId).then(result => {
          this.fileUploadedEvent.emit(result);
        }).catch(error => console.error(error));
      } else {
        this.openDialog(file)
      }
    }
  }

  openDialog(file?: File) {
    console.log("defaultsample:", defaultSample)
    const dialogRef = this.dialog.open(EditSampleComponent, {
      width: '600px',
      data: {sample: this.defaultSample, fileToUpload: file ? file : undefined}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialogClosed:', result)
      window.location.reload();
    })
  }

}
