import {Component, Inject, OnInit} from '@angular/core';
import {Supplier} from "../../../types/backend-types";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SuppliersService} from "../../../services/suppliers.service";

@Component({
  selector: 'app-edit-supplier',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.scss']
})
export class EditSupplierComponent implements OnInit {
  supplier: Supplier = {
    name: '', data: {
      contactName: '',
      street: '',
      city: '',
      postCode: '',
      email: '',
      phone: ''
    }
  };
  supplierBackup: Supplier | undefined;
  updating: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditSupplierComponent>, private suppliersService: SuppliersService) {
  }

  ngOnInit(): void {
    if (this.data.supplier) {
      this.supplier = {...this.data.supplier};
      this.supplierBackup = JSON.parse(JSON.stringify(this.data.supplier));
    }
  }

  createSupplier() {
    this.updating = true;
    this.suppliersService.createSupplier(this.supplier).subscribe((response: any) => {
      console.log(response);
      if (response.id) {
        this.updating = false;
        this.dialogRef.close(`Supplier ${response.id} created`)
      }
    })
  }

  updateSupplier() {
    if (this.supplier.id) {
      this.updating = true;
      this.suppliersService.updateSupplier(this.supplier.id, this.supplier).subscribe((response: any) => {
        console.log(response)
        if (response.id) {
          this.updating = false;
          this.supplier = response;
          this.dialogRef.close(`Supplier ${response.id} updated`)
        }
      })
    }
  }

  deleteSupplier() {
    if (this.supplier.id) {
      this.suppliersService.deleteSupplier(this.supplier.id).subscribe(response => {
        this.dialogRef.close("User Canceled")
      })
    }
  }

  restoreAndClose() {
    if (this.supplier.id && this.supplierBackup?.id) {
      this.suppliersService.updateSupplier(this.supplier.id, this.supplierBackup).subscribe(response => {
        console.log(response);
        this.dialogRef.close("Edit Canceled")
      })
    }
  }

  close() {
    this.dialogRef.close("Supplier creation cancelled")
  }

}
