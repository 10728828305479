import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SamplesService} from "../../../services/samples.service";
import {Sample} from "../../../types/backend-types";

@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.scss']
})
export class SamplesComponent implements OnInit {
  @Output()
  selectionEvent: EventEmitter<any> = new EventEmitter<any>();

  tabSelected = 2;
  displayedSamples: Array<Sample> = [];
  imageSelected: number = 0;
  sampleSelected: number = 0;
  samplesInProgress: Array<Sample> = [];
  samplesWorking: Array<Sample> = [];
  samplesArchived: Array<Sample> = [];
  allSamples: Array<Sample> = [];

  constructor(private backend: SamplesService) {
  }

  ngOnInit(): void {
    this.getAllSamples();
  }

  getAllSamples() {
    this.backend.getAllSamples().subscribe((response: Array<Sample>) => {
      this.allSamples = response;
      this.filterSamples(response);
      this.displayedSamples = this.samplesWorking;
      console.log(response)
    });
  }

  filterSamples(samplesToFilter: Array<Sample>) {
    this.samplesArchived = samplesToFilter.filter(sample => {
      return sample.analysisStatus === "ARCHIVED"
    })
    this.samplesWorking = samplesToFilter.filter(sample => {
      return sample.analysisStatus === "WORKING"
    })
    this.samplesInProgress = samplesToFilter.filter(sample => {
      return sample.analysisStatus === "IN_PROGRESS"
    })
  }

  tabChange(tab: number) {
    this.tabSelected = tab;
    switch (this.tabSelected) {
      case 1:
        this.filterSamples(this.allSamples);
        this.displayedSamples = this.samplesInProgress;
        break;
      case 2:
        this.filterSamples(this.allSamples);
        this.displayedSamples = this.samplesWorking;
        break;
      case 3:
        this.filterSamples(this.allSamples);
        this.displayedSamples = this.samplesArchived;
        break;
      default:
        this.displayedSamples = []
        break;
    }
  }

  imageSelect(index: number) {
    this.imageSelected = index;
    this.selectionEvent.emit({
      sample: this.displayedSamples[this.sampleSelected],
      image: this.displayedSamples[this.sampleSelected].images[this.imageSelected]
    })
  }

  sampleSelect(index: number) {
    this.sampleSelected = index;
    this.selectionEvent.emit({sample: this.displayedSamples[this.sampleSelected]})
  }

  updateSample(sample: Sample) {
    const index = this.allSamples.findIndex((element: Sample) => {
      return element.id === sample.id
    })
    this.allSamples[index].analysisStatus = sample.analysisStatus;
    this.tabChange(this.tabSelected)
  }

  sampleRemoved(sample: Sample) {
    const index = this.allSamples.findIndex((element: Sample) => {
      return element.id === sample.id
    })
    this.allSamples.splice(index, 1);
    this.tabChange(this.tabSelected)

  }
}
