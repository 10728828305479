import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-fidelity-slider',
  templateUrl: './fidelity-slider.component.html',
  styleUrls: ['./fidelity-slider.component.scss']
})
export class FidelitySliderComponent implements OnInit, OnChanges {
  @Input() percentValue!: number;

  sliderColor: string = '#11ac00';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['percentValue'];
    if (change && change.previousValue !== change.currentValue) {
      if (this.percentValue < 21) {
        this.sliderColor = "#11ac00"
      } else if (this.percentValue > 20 && this.percentValue < 80) {
        this.sliderColor = "#e78d06";
      } else {
        this.sliderColor = "#ac1500"
      }
    }


  }

  displayFunction(value: number): string {
    return `${value}%`
  }
}
