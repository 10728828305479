import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-status',
  templateUrl: './image-status.component.html',
  styleUrls: ['./image-status.component.scss']
})
export class ImageStatusComponent implements OnInit, AfterViewInit {
  @Input()
  label!:any;
  @Input()
  done!:boolean;
  @Output()
  deleteMeEvent:EventEmitter<boolean>=new EventEmitter<boolean>()

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //this.label=this.label.toString().split('/').pop()
  }

  deleteMe(){
    this.deleteMeEvent.emit(true)
  }

}
