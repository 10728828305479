import {Injectable} from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3'
import {environment} from "../../environments/environment";
import {Image} from "../types/backend-types";

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {
  constructor() {
  }

  uploadImage(file: any, sampleId: string): Promise<Image> {
    const timestamp=new Date().toTimeString().split(' ')[0];
    const bucket = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
      region: environment.s3.region
    });
    const params = {
      Bucket: environment.s3.fullBucket,
      Key: `${sampleId}/${btoa(timestamp)}${file.name}`,
      Body: file,
      ContentType: file.type
    };
    return new Promise(function (resolve, reject) {
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          reject(err)
        }
        console.log('File Uploaded.', data);
        resolve({
          url:data.Location,
          thumbnail:`https://${environment.s3.thumbnailBucket}.S3.${environment.s3.region}.amazonaws.com/${data.Key}`,
          name:file.name
        })
      });
    })

  }
}
