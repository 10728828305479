<div *ngIf="sample.id">
  <h1 mat-dialog-title>Wgraj zdjęcia</h1>
  <div mat-dialog-content id="sample-editor">
    <mat-form-field appearance="outline">
      <mat-label>
        Nazwa próbki
      </mat-label>
      <input matInput [(ngModel)]="sample.name" (focusout)="updateSample()" required #sampleName>
      <mat-error *ngIf="sampleName.validity.valueMissing"> Pole waymagane</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        Rozmiar zdjęcia
      </mat-label>
      <input matInput [(ngModel)]="sample.pixelSize" (focusout)="updateSample()" required pattern="^[0-9.]*$"
             #pixelSize>
      <div id="suffixer" matSuffix>µm/px
        <mat-icon svgIcon="app_info" [matTooltip]="pixelSizeTooltip"></mat-icon>
      </div>

      <mat-error *ngIf="pixelSize.validity.patternMismatch">Niepoprawna wartość</mat-error>
      <mat-error *ngIf="pixelSize.validity.valueMissing"> Pole waymagane</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>
        Wybór dostawcy
      </mat-label>
      <input type="text" matInput [matAutocomplete]="auto" [formControl]="supplierControl" required #supplier>
      <mat-autocomplete #auto [displayWith]="supplierDisplayFn" (optionSelected)="supplierPicked($event)">
        <mat-option *ngFor="let supplier of filteredSuppliers|async" [value]="supplier">
          {{supplier.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="supplier.validity.valueMissing">Wybierz dostawcę</mat-error>
    </mat-form-field>
    <app-uploader [sampleId]="sample.id?sample.id:''" [uploadAllowed]=uploadAllowed
                  (uploadStartedEvent)="uploadStartedHandler($event)"
                  (fileUploadedEvent)="uploadDoneHandler($event)" [disabled]="sample.images.length>15"
                  [ngStyle]="{'opacity': sample.images.length>15 ? '30%' : '100%'}"></app-uploader>
    <p class="error" *ngIf="!validateImagesCount()">
      Do przeprowadzenia analizy potrzeba jeszcze co najmniej {{5 - sample.images.length}}
      <span *ngIf="6-sample.images.length===1">zdjęcie</span>
      <span *ngIf="6-sample.images.length>1&&6-sample.images.length<5">zdjęcia</span>
      <span *ngIf="6-sample.images.length>4">zdjęć</span>
    </p>
    <ng-container *ngIf="sample?.images">
      <ng-container *ngFor="let item of sample.images">

        <app-image-status [label]="item.name" [done]="true"
                          (deleteMeEvent)="deleteImageHandler($event, item)"></app-image-status>

      </ng-container>

    </ng-container>
    <app-image-status *ngIf="tempFiles.length>0" [label]="tempFiles[0]" [done]="false"></app-image-status>
    <button mat-raised-button color="primary" [disabled]="sample?.images!.length<5 || sample?.images!.length>16"
            (click)="startAnalysis()" *ngIf="!analysing">Wykonaj analizę
    </button>
    <button mat-raised-button color="primary" disabled *ngIf="analysing">
      <div id="button-label">Analiza w toku
        <mat-spinner diameter="24" id="spinner"></mat-spinner>
      </div>
    </button>
    <button mat-stroked-button (click)="saveAndExit()">Zapisz wersję roboczą</button>
    <button mat-stroked-button (click)="isEditMode?restoreAndClose():deleteSample()">Anuluj</button>

  </div>
</div>
<div *ngIf="!sample.id">
  <span id="start-info">Tworzenie nowej próbki<mat-spinner diameter="20"
                                                           *ngIf="!sampleCreationErrored"></mat-spinner> <span
    *ngIf="sampleCreationErrored">&nbsp;nie powiodło się.</span></span>
</div>
