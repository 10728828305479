<ng-container *ngIf="!sample">
  <h3>Wgraj swoją próbkę </h3>
  <p>Pamiętaj że minimalna ilość zdjęć dodana do próbki wynosi 5, a maksymalna 16. Poniżej/powyżej tej wartości analiza
    nie będzie możliwa.</p>
  <div class="center">
    <app-uploader sampleId="" [uploadAllowed]="true"></app-uploader>
  </div>
</ng-container>
<ng-container *ngIf="sample">
  <div id="header-container">
    <h3 *ngIf="sample.resultReady" class="header">Wyniki analizy próbki: <span
      class="sub-header">{{sample.name}}</span> {{sample.supplierName}}</h3>
    <h3 *ngIf="!sample.resultReady" class="header">Oczekuje na anlizę próbki: <span
      class="sub-header">{{sample.name}}</span> {{sample.supplierName}}</h3>
    <button *ngIf="!sample.resultReady" mat-stroked-button class="edit-sample-button" (click)="openDialog()"> + Edytuj
      próbkę
    </button>
  </div>
  <img [src]="image?image?.thumbnail:sample.images[0].thumbnail" class="no-image" *ngIf="sample.images.length>0">
  <img src="assets/no-image.png" class="no-image" *ngIf="sample.images.length===0">

  <ng-container *ngIf="sample.resultReady &&sample.result">
    <h4>Analiza wirowania</h4>
    <div class="two-set">
      <p *ngIf="sample.result.centrifuged && !errorMessage" class="bad result">Mleko wirowane</p>
      <p *ngIf="!sample.result.centrifuged && !errorMessage" class="good result">Mleko niewirowane</p>
      <p *ngIf="errorMessage" class="bad result">Błąd analizy próbki:</p>
      <p *ngIf="errorMessage" id="error-message">{{errorMessage}}</p>
      <app-fidelity-slider [percentValue]="probabilityPercentValue"
                           *ngIf="sample.result.probability||sample.result.probability===0"></app-fidelity-slider>
    </div>
    <div class="two-set" *ngIf="!errorMessage"><span>Rekomendacje </span>
      <mat-icon
        matTooltip="Rekomendacja dokonana na podstawie stopnia dyspersji i udziału poszczególnych klas MFG"
        svgIcon="app_info"></mat-icon>
    </div>

    <div id="recommendations" *ngIf="!errorMessage">
      <div class="recommendation" [ngClass]="butterRecommended?'none':'transparent'">
        <img src="assets/butter.svg"/>
        <span>Masło</span>
        <div class="strike" *ngIf="!butterRecommended"></div>
      </div>
      <div class="recommendation" [ngClass]="cheeseRecommended?'none':'transparent'">
        <img src="assets/cheese.svg"/>
        <span>Ser</span>
        <div class="strike" *ngIf="!cheeseRecommended"></div>
      </div>
      <div class="recommendation" [ngClass]="milkRecommended?'none':'transparent'">
        <img src="assets/milk.svg"/>
        <span>Mleko UHT</span>
        <div class="strike" *ngIf="!milkRecommended"></div>
      </div>

    </div>

  </ng-container>
  <div id="fieldArranger">
    <mat-form-field appearance="outline" id="notes">
      <mat-placeholder>Miejsce na notatki...</mat-placeholder>
      <textarea matInput [(ngModel)]="sample.notes" (focusout)="updateNotes()" cdkTextareaAutosize></textarea>
    </mat-form-field>
    <mat-icon svgIcon="app_edit"></mat-icon>
  </div>


</ng-container>

